import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { HelmetProvider } from "react-helmet-async";
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminLogin from "./components/auth/AdminLogin";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import ProfileProcessorPage from "./components/image/ProfileProcessorPage";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import BlogPage from "./pages/Blogpage";
import { AppointmentProvider } from "./contexts/AppointmentContext";
import About from "./pages/About";
import Contact from "./pages/contact";
import GetStartedPage from "./pages/GetStartedPage";
import SuccessPage from "./pages/SuccessPage";
import HomePage from "./pages/HomePage";
import FloatingWhatsApp from "./components/ui/FloatingWhatsApp";
import SEO from "./components/SEO";
import Company from "./pages/Company";
import TermsAndConditions from "./components/legal/terms-and-conditions/TermsAndConditions";
import PrivacyPolicy from "./components/legal/privacy-policy/PrivacyPolicy";
import TrademarkPolicy from "./components/legal/trademark-policy/TrademarkPolicy";
import ComplianceStatement from "./components/legal/compliance-statement/ComplianceStatement";
import FraudAlert from "./components/legal/fraud-alert";
// Import animations
import "./styles/animations.css";

// Import Updated Services Pages
import Services from "./pages/Services";
import BusinessSetup from "./pages/services/BusinessSetup";
import AccountingServices from "./pages/services/AccountingServices";
import AuditServices from "./pages/services/AuditServices";
import LegalServices from "./pages/services/LegalServices";
import ComplianceServices from "./pages/services/ComplianceServices";
import HRsPROServices from "./pages/services/HRsPROServices";
import DocumentationClearing from "./pages/services/DocumentationClearing";
import TaxServices from "./pages/services/TaxServices";
import DataAnalytics from "./pages/services/DataAnalytics";
import AMLServices from "./pages/services/AMLServices";
import ESGServices from "./pages/services/ESGServices";

const App = () => {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <AuthProvider>
          <AppointmentProvider>
            <div className="app">
              <Header />
              <main>
                <Routes>
                  {/* Main Pages */}
                  <Route
                    path="/"
                    element={
                      <>
                        <SEO
                          title="Luminous Bluewaters Consultancy | Corporate Services Dubai"
                          description="Leading corporate service provider in Dubai, UAE. Specializing in business consulting, HR services, audit, and data analytics solutions."
                          canonicalUrl="https://lumi-blue.com/"
                        />
                        <HomePage />
                      </>
                    }
                  />

                  {/* Terms and Conditions Page */}
                  <Route
                    path="/terms-and-conditions"
                    element={
                      <>
                        <SEO
                          title="Terms and Conditions | Luminous Bluewaters Consultancy"
                          description="Terms and conditions for using Luminous Bluewaters Consultancy's services and website."
                          canonicalUrl="https://lumi-blue.com/terms-and-conditions"
                        />
                        <TermsAndConditions />
                      </>
                    }
                  />

                  {/* Privacy Policy Page */}
                  <Route
                    path="/privacy-policy"
                    element={
                      <>
                        <SEO
                          title="Privacy Policy | Luminous Bluewaters Consultancy"
                          description="Privacy policy outlining how Luminous Bluewaters Consultancy handles and protects your personal information."
                          canonicalUrl="https://lumi-blue.com/privacy-policy"
                        />
                        <PrivacyPolicy />
                      </>
                    }
                  />

                  {/* Trademark Policy Page */}
                  <Route
                    path="/trademark-policy"
                    element={
                      <>
                        <SEO
                          title="Trademark Policy | Luminous Bluewaters Consultancy"
                          description="Trademark policy outlining the use and protection of Luminous Bluewaters Consultancy's brand assets and intellectual property."
                          canonicalUrl="https://lumi-blue.com/trademark-policy"
                        />
                        <TrademarkPolicy />
                      </>
                    }
                  />

                  {/* Compliance Statement Page */}
                  <Route
                    path="/compliance-statement"
                    element={
                      <>
                        <SEO
                          title="Compliance Statement | Luminous Bluewaters Consultancy"
                          description="Learn about our commitment to ethical business practices, anti-money laundering, data protection, and professional standards in Dubai, UAE."
                          canonicalUrl="https://lumi-blue.com/compliance-statement"
                        />
                        <ComplianceStatement />
                      </>
                    }
                  />
                  {/* Fraud Alert Page */}
                  <Route
                    path="/fraud-alert"
                    element={
                      <>
                        <SEO
                          title="Fraud Alert | Luminous Bluewaters Consultancy"
                          description="Important information about protecting yourself from fraudulent activities and unauthorized use of Luminous Bluewaters Consultancy's name."
                          canonicalUrl="https://lumi-blue.com/fraud-alert"
                        />
                        <FraudAlert />
                      </>
                    }
                  />

                  {/* About Page */}
                  <Route
                    path="/about"
                    element={
                      <>
                        <SEO
                          title="About Us | Luminous Bluewaters Consultancy"
                          description="Learn about Luminous Bluewaters Consultancy's mission, values, and expertise in providing corporate services in Dubai, UAE."
                          canonicalUrl="https://lumi-blue.com/about"
                        />
                        <About />
                      </>
                    }
                  />

                  {/* Company Page */}
                  <Route
                    path="/company"
                    element={
                      <>
                        <SEO
                          title="Our Company | Luminous Bluewaters Consultancy"
                          description="Meet our expert team at Luminous Bluewaters Consultancy. Over 100 years of combined experience in corporate services, legal, finance, and business solutions."
                          canonicalUrl="https://lumi-blue.com/company"
                        />
                        <Company />
                      </>
                    }
                  />

                  {/* Blog Page */}
                  <Route
                    path="/blog"
                    element={
                      <>
                        <SEO
                          title="Blog | Luminous Bluewaters Consultancy"
                          description="Stay informed with insights on business, corporate services, and UAE market trends from our expert team."
                          canonicalUrl="https://lumi-blue.com/blog"
                        />
                        <BlogPage />
                      </>
                    }
                  />

                  {/* Get Started Page */}
                  <Route
                    path="/get-started"
                    element={
                      <>
                        <SEO
                          title="Get Started | Luminous Bluewaters Consultancy"
                          description="Begin your journey with Luminous Bluewaters Consultancy. Schedule a consultation for our corporate services in Dubai."
                          canonicalUrl="https://lumi-blue.com/get-started"
                        />
                        <GetStartedPage />
                      </>
                    }
                  />

                  {/* Contact Page */}
                  <Route
                    path="/contact"
                    element={
                      <>
                        <SEO
                          title="Contact Us | Luminous Bluewaters Consultancy"
                          description="Get in touch with our team for expert corporate services in Dubai. We're here to help your business succeed."
                          canonicalUrl="https://lumi-blue.com/contact"
                        />
                        <Contact />
                      </>
                    }
                  />

                  {/* Services Main Page */}
                  <Route
                    path="/services"
                    element={
                      <>
                        <SEO
                          title="Our Services | Luminous Bluewaters Consultancy"
                          description="Explore our comprehensive range of corporate services in Dubai, from business setup to advisory and compliance solutions."
                          canonicalUrl="https://lumi-blue.com/services"
                        />
                        <Services />
                      </>
                    }
                  />

                  {/* Services Sub-Pages */}
                  <Route
                    path="/services/business-setup"
                    element={
                      <>
                        <SEO
                          title="Business setup consultants in Dubai, UAE | Luminous bluewaters"
                          description="Set up your business in Dubai with expert guidance. Mainland, Freezone, or offshore company setup- we make company formation easy. Contact us today."
                          canonicalUrl="https://lumi-blue.com/services/business-setup"
                        />
                        <BusinessSetup />
                      </>
                    }
                  />

                  <Route
                    path="/services/accounting"
                    element={
                      <>
                        <SEO
                          title="Accounting & bookkeeping services in Dubai& UAE|Luminous bluewaters"
                          description="Ensure flawless financial statements with accurate accounting and bookkeeping services in Dubai. Get expert guidance on corporate finance. Contact us now."
                          canonicalUrl="https://lumi-blue.com/services/accounting"
                        />
                        <AccountingServices />
                      </>
                    }
                  />

                  <Route
                    path="/services/audit-assurance"
                    element={
                      <>
                        <SEO
                          title="Expert Audit & Assurance Services in Dubai, UAE | Luminous Bluewaters"
                          description="Get comprehensive audit and assurance services from certified auditors in Dubai. We specialize in a wide range of auditing services. Contact us today."
                          canonicalUrl="https://lumi-blue.com/services/audit-assurance"
                        />
                        <AuditServices />
                      </>
                    }
                  />

                  <Route
                    path="/services/legal"
                    element={
                      <>
                        <SEO
                          title="Dubai's Best Corporate Legal Services | Luminous Bluewaters "
                          description="Expert corporate legal services in Dubai: advisory, employment law, M&A, copyright, patents, and more. Partner with Luminous Bluewaters. Contact us today!"
                          canonicalUrl="https://lumi-blue.com/services/legal"
                        />
                        <LegalServices />
                      </>
                    }
                  />

                  <Route
                    path="/services/compliance"
                    element={
                      <>
                        <SEO
                          title="Top Compliance Services in Dubai | Luminous Bluewaters "
                          description="Boost your business with expert compliance solutions from Dubai's leading firm. Enhance governance and ensure regulatory compliance. Contact us today."
                          canonicalUrl="https://lumi-blue.com/services/compliance"
                        />
                        <ComplianceServices />
                      </>
                    }
                  />

                  <Route
                    path="/services/hrs-pro"
                    element={
                      <>
                        <SEO
                          title="HR Consultancy & PRO Services in Dubai| Luminous Bluewaters"
                          description="Expert human resource consulting, PRO, and visa services in Dubai. We specialize in all types of visa processing. 20+ years of expertise.Contact today. "
                          canonicalUrl="https://lumi-blue.com/services/hrs-pro"
                        />
                        <HRsPROServices />
                      </>
                    }
                  />

                  <Route
                    path="/services/aml"
                    element={
                      <>
                        <SEO
                          title="AML & CFT compliance services in Dubai | Luminous Bluewaters"
                          description="Expert AML & CFT advisory in Dubai. Services include AML policies, risk assessment, profiling, and compliance support. Trusted solutions. Contact us today."
                          canonicalUrl="https://lumi-blue.com/services/aml"
                        />
                        <AMLServices />
                      </>
                    }
                  />

                  <Route
                    path="/services/esg-sustainability"
                    element={
                      <>
                        <SEO
                          title="ESG & Sustainability Experts in Dubai | Luminous Bluewaters"
                          description="Enhance your company's ESG performance with our environmental, social, and governance (ESG) and sustainability advisory services in Dubai. Contact us."
                          canonicalUrl="https://lumi-blue.com/services/esg-sustainability"
                        />
                        <ESGServices />
                      </>
                    }
                  />

                  <Route
                    path="/services/documentation"
                    element={
                      <>
                        <SEO
                          title="Documentation Services Dubai | Luminous Bluewaters Consultancy"
                          description="Professional documentation services including attestation, notarisation, and certified translations in Dubai."
                          canonicalUrl="https://lumi-blue.com/services/documentation"
                        />
                        <DocumentationClearing />
                      </>
                    }
                  />

                  <Route
                    path="/services/tax"
                    element={
                      <>
                        <SEO
                          title="Corporate tax and VAT services in Dubai| Luminous Bluewaters "
                          description="Expert VAT registration and corporate taxation services in Dubai. Ensure compliance and optimize tax strategy with our professional guidance. Contact us."
                          canonicalUrl="https://lumi-blue.com/services/tax"
                        />
                        <TaxServices />
                      </>
                    }
                  />

                  <Route
                    path="/services/data-analytics"
                    element={
                      <>
                        <SEO
                          title=" Business Intelligence services in Dubai| Luminous Bluewaters"
                          description="Automate processes with top business intelligence (BI) tools. Experts in data visualization, predictive analytics, and database management. Contact Today!"
                          canonicalUrl="https://lumi-blue.com/services/data-analytics"
                        />
                        <DataAnalytics />
                      </>
                    }
                  />

                  {/* Admin and Protected Routes */}
                  <Route path="/login" element={<AdminLogin />} />
                  <Route path="/success" element={<SuccessPage />} />
                  <Route
                    path="/admin"
                    element={
                      <ProtectedRoute>
                        <AdminDashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/profile-processor"
                    element={
                      <ProtectedRoute>
                        <ProfileProcessorPage />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </main>
              <Footer />
              <FloatingWhatsApp
                phoneNumber="+971562886878"
                message="Hello! I'm interested in exploring how Luminous Bluewaters Consultancy can help transform my business. Could you provide more information about your services?"
              />
            </div>
          </AppointmentProvider>
        </AuthProvider>
      </BrowserRouter>
    </HelmetProvider>
  );
};

export default App;

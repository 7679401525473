import React, { useState } from "react";
import { Box, Container, Tab, Tabs, Typography } from "@mui/material";
import TabPanel from "../../components/TabPanel";
import videoSource from "../../assets/video/optimized/hrpro.mp4";
import ServiceHero from "../../components/ServiceHero";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { ArrowRight, ChevronDown } from "lucide-react";
import useScrollToTop from "../../hooks/useScrollToTop";
import styles from "./HRsPROServices.module.css";

const FAQItem = ({
  question,
  answer,
}: {
  question: string;
  answer: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200">
      <button
        className="w-full flex justify-between items-center py-4 px-4 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium text-left">{question}</span>
        <ChevronDown
          className={`w-5 h-5 transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isOpen && (
        <div className="px-4 pb-4">
          <p className="text-gray-600">{answer}</p>
        </div>
      )}
    </div>
  );
};

function HRsPROServices() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  useScrollToTop();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const listVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: (custom: number) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: custom * 0.2,
        duration: 0.5,
      },
    }),
  };

  const faqData = [
    {
      question: "What HR & PRO services do you offer in Dubai?",
      answer:
        "We offer comprehensive HR & PRO services including visa processing, employee documentation, payroll management, HR consultancy, and government relations services. Our services cover both mainland and free zone companies in Dubai and the UAE.",
    },
    {
      question: "How long does the visa process typically take in Dubai?",
      answer:
        "The visa processing time varies depending on the type of visa and circumstances. Generally, employment visas take 2-3 weeks, while tourist visas can be processed in 3-4 working days. This timeline includes all necessary steps from initial application to visa stamping.",
    },
    {
      question: "What documents are required for employee visa processing?",
      answer:
        "Required documents typically include passport copies, educational certificates, passport-size photographs, job offer letter, and company documents. The exact requirements may vary based on the type of visa and the employee's nationality.",
    },
    {
      question: "Do you handle license renewals for Dubai companies?",
      answer:
        "Yes, we provide comprehensive license renewal services for both mainland and free zone companies. This includes preparing all necessary documentation, coordinating with relevant authorities, and ensuring timely submissions to avoid any business disruptions.",
    },
    {
      question: "What is included in your HR outsourcing services?",
      answer:
        "Our HR outsourcing services include employee documentation, payroll processing, leave management, HR policy development, performance management systems, recruitment support, and compliance with UAE labor laws.",
    },
  ];

  // Common List Component
  const AnimatedList = ({ items }: { items: string[] }) => (
    <Box component="ul" sx={{ pl: 2, listStyleType: "none" }}>
      {items.map((item, index) => (
        <motion.li
          key={index}
          variants={listVariants}
          initial="hidden"
          animate="visible"
          custom={index}
          className="mb-4 flex items-center text-gray-700"
        >
          <Box
            component="span"
            sx={{
              width: "8px",
              height: "8px",
              backgroundColor: "#8B5CF6",
              borderRadius: "50%",
              display: "inline-block",
              marginRight: "1rem",
            }}
          />
          <span className="text-base leading-relaxed">{item}</span>
        </motion.li>
      ))}
    </Box>
  );

  // Common Section Header Component
  const SectionHeader = ({
    title,
    delay = 0,
  }: {
    title: string;
    delay?: number;
  }) => (
    <motion.h3
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay, duration: 0.5 }}
      className="text-2xl font-bold text-[#2D1B69] mt-8 mb-4"
    >
      {title}
    </motion.h3>
  );

  // Common CTA Button Component
  const CTAButton = ({ text, delay = 0 }: { text: string; delay?: number }) => (
    <Box sx={{ mt: 6, display: "flex", justifyContent: "center", gap: 2 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay, duration: 0.5 }}
      >
        <button
          onClick={() => navigate("/get-started")}
          className="group relative px-8 py-4 rounded-full bg-[#C4B5FD] text-[#2D1B69] font-medium 
                    border-2 border-[#8B5CF6] hover:bg-[#8B5CF6] hover:text-white
                    transition-all duration-300 transform hover:scale-105
                    shadow-lg hover:shadow-xl"
        >
          <span className="relative z-10 flex items-center justify-center gap-2">
            {text}
            <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform duration-300" />
          </span>
        </button>
      </motion.div>
    </Box>
  );

  return (
    <div className={styles.hrContainer}>
      <ServiceHero
        title="Hassle-free Business Operations"
        subtitle="Expert HR & PRO Services"
        description="Streamline your business operations with our comprehensive HR & PRO (Public Relations Officer) services. At Luminous Bluewaters, we manage essential administrative and compliance tasks, enabling you to focus on your business growth while ensuring full adherence to UAE labor laws and regulations."
        videoSource={videoSource}
      />

      <Container maxWidth="lg" className={styles.sectionContainer}>
        {/* Rest of your component content remains the same */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="HR and PRO services tabs"
          >
            <Tab label="HR Outsourcing" />
            <Tab label="Visa Services" />
            <Tab label="Visa Processing" />
            <Tab label="License & Governance" />
            <Tab label="Document Services" />
          </Tabs>
        </Box>

        {/* HR Outsourcing Tab */}
        <TabPanel value={value} index={0}>
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold text-[#2D1B69] mb-6"
          >
            Customized HR Solutions for Your Business
          </motion.h2>

          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="text-gray-700 text-lg leading-relaxed mb-4"
          >
            Managing HR processes in a dynamic business environment can be
            time-consuming. In Dubai, UAE, For SME's and startups acquiring a HR
            team may be challenging. There comes Luminous Bluewaters
            Consultancy. We give assistance in managing all the Human Resources
            and PRO services related matters, the value proposition is you save
            cost while outsourcing to us with our expertise and skills in UAE.
          </motion.p>

          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="text-gray-700 text-lg leading-relaxed mb-6"
          >
            Our HR Outsourcing and Advisory services are designed to reduce
            operational burdens while improving efficiency.
          </motion.p>

          <SectionHeader title="Our Services Include:" delay={0.6} />

          <AnimatedList
            items={[
              "Strategic HR planning and consultation",
              "Employee documentation management",
              "Payroll outsourcing and compliance",
              "Recruitment support and onboarding assistance",
              "Employee grievance handling",
            ]}
          />

          <SectionHeader title="Benefits:" delay={1.6} />

          <AnimatedList
            items={[
              "Cost-effective and scalable solutions",
              "Compliance with local labor laws",
              "Focus on core business activities",
            ]}
          />

          <CTAButton text="Optimize your HR today!" delay={2.0} />
        </TabPanel>

        {/* Visa Services Tab */}
        <TabPanel value={value} index={1}>
          <Typography variant="h4" gutterBottom>
            Comprehensive Visa Services
          </Typography>

          <Typography paragraph>
            The UAE offers a variety of visa options for those planning to visit
            Dubai. We assist you in securing the visa that best suits your
            needs.
          </Typography>

          <AnimatedList
            items={[
              "Employment Visa",
              "Investor or Partner Visa",
              "Golden Visa",
              "Dependent Visa",
              "Freelancer Visa",
              "Tourist Visa",
            ]}
          />

          <CTAButton text="Start Your Visa Process Today" delay={1.5} />
        </TabPanel>

        {/* Visa Processing Tab */}
        <TabPanel value={value} index={2}>
          <Typography variant="h4" gutterBottom>
            Visa Processing Services
          </Typography>

          <SectionHeader
            title="Employee Visa Processing and Renewals"
            delay={0.2}
          />
          <Typography paragraph>
            Ensure your workforce is always compliant with UAE immigration
            regulations. We handle the entire visa process, including renewals,
            to save you time and hassle.
          </Typography>
          <SectionHeader title="What We Offer:" delay={0.4} />
          <AnimatedList
            items={[
              "Initial visa applications for employees and dependents",
              "Renewal of visas before expiration",
              "Coordination with immigration authorities",
            ]}
          />

          <SectionHeader title="Work Permit Processing" delay={0.8} />
          <Typography paragraph>
            A work permit is mandatory for onboarding employees in the UAE. We
            manage the process from start to finish to ensure timely approvals.
          </Typography>
          <SectionHeader title="Our Expertise Includes:" delay={1.0} />
          <AnimatedList
            items={[
              "New work permit applications",
              "Renewals for existing permits",
              "Compliance with Ministry of Labor regulations",
            ]}
          />

          <SectionHeader title="GCC Work Permit Processing" delay={1.4} />
          <Typography paragraph>
            Expanding into GCC countries? We offer expert assistance with work
            permit processing across the GCC region, ensuring seamless mobility
            for your workforce.
          </Typography>

          <CTAButton text="Get Expert Visa Processing Support" delay={2.6} />
        </TabPanel>

        {/* License & Governance Tab */}
        <TabPanel value={value} index={3}>
          <Typography variant="h4" gutterBottom>
            License Renewal and Governance Solutions
          </Typography>

          <Typography paragraph>
            Keep your business operations uninterrupted with timely license
            renewals and governance support. We ensure your business stays
            compliant with UAE regulations.
          </Typography>

          <SectionHeader title="Services Include:" delay={0.4} />
          <AnimatedList
            items={[
              "Trade license renewal assistance",
              "Managing updates to company documentation",
              "Ensuring compliance with regulatory requirements",
            ]}
          />

          <CTAButton text="Ensure Your Business Compliance" delay={1.0} />
        </TabPanel>

        {/* Document Services Tab */}
        <TabPanel value={value} index={4}>
          <Typography variant="h4" gutterBottom>
            Document Clearing Services
          </Typography>

          <Typography variant="h5" gutterBottom>
            Handling Documents with Precision
          </Typography>

          <Typography paragraph>
            We provide seamless Document Clearing Services to ensure your
            business operations are smooth and compliant. Our team handles all
            your documentation needs with precision and efficiency.
          </Typography>

          <SectionHeader title="Attestation and Notarization" delay={0.3} />
          <Typography paragraph>
            Official documentation often requires authentication for legal
            validity in the UAE and abroad. We manage the attestation and
            notarization of your documents, ensuring they meet all legal and
            regulatory requirements.
          </Typography>

          <SectionHeader title="Services Include:" delay={0.5} />
          <AnimatedList
            items={[
              "Attestation from government authorities",
              "Notarization of legal documents",
              "Embassy and consulate attestations",
              "Document verification",
            ]}
          />

          <SectionHeader
            title="Translation (Legal and Certified)"
            delay={0.8}
          />
          <Typography paragraph>
            Our certified translation services ensure accuracy, professionalism,
            and compliance with legal standards.
          </Typography>

          <CTAButton text="Let Us Handle Your Documentation" delay={1.2} />
        </TabPanel>

        {/* FAQ Section */}
        <section className="py-16 px-4 max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-8">
            Frequently Asked Questions
          </h2>
          <div className="bg-white rounded-lg shadow-lg">
            {faqData.map((faq, index) => (
              <FAQItem
                key={index}
                question={faq.question}
                answer={faq.answer}
              />
            ))}
          </div>
        </section>
      </Container>
    </div>
  );
}

export default HRsPROServices;
